<template>
  <div>
    <div class="perInfo A4Container flexCloumnCenter">
      <div class="form">
        <div class="table" style="height: 100%;">
          <div style="width: 100%;height: calc( 100% - 0px );">
            <el-row class="" style="height: calc( 100% - 0px );">
              <el-col :span="2" class="cell flexCenter" style="height: 100%;border-left: 0;font-weight: 700;">
                项 <br />目 <br />简 <br />介
              </el-col>
              <el-col :span="22" class="cell flex_skill" style="height: 100%;padding: 10px;display: inline-block;">
                <div class="commit el-row" style="position: relative;width: 100%;height: 100%;padding: 10px;">
                  <div style="width: 100%;height:100%;word-break: break-all">
                    <div :id="'divCntnrB'" style="width: 100%;height: calc( 100% - 00px );overflow: hidden">
                      <div :id="'resizeDivB'" style="width: 100%;word-break: break-all">
                        {{ formData.intro }}
                      </div>
                    </div>

                  </div>
                </div>
              </el-col>
            </el-row>
          </div>










        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    stuinfo: {
      type: Object,
      default: {}
    },
    principal_info: {
      type: Object,
      default: {}
    },
    memberList: {
      type: Array,
      default: []
    },
    teacherList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      isShowModal: true,
      formData: {}

    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let _this = this
      let info = JSON.parse(JSON.stringify(_this.stuinfo))
      if (info.photo) {
        info.photo = info.photo.split("?")[0] + "?x-oss-process=image/resize,w_200"
      }
      _this.formData = info
      if (info.stu_name) {
        _this.$nextTick(() => {
          _this.resizeB()
        })
      } else {
        setTimeout(function () {
          _this.init()
        }, 400)
      }
    },
     resizeB() {
      let divHeight = document.getElementById(`divCntnrB`).offsetHeight
      let textHeight = document.getElementById(`resizeDivB`).offsetHeight
      console.log('b', this.idx, divHeight, textHeight)
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivB`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivB`).offsetHeight
        i++
      }
    }
    
  }
}
</script>

<style lang="less" type="text/less">
.perInfo .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.perInfo .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
.flexStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.perInfo {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 0px);
    border: 1px solid #000;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row {
      //border-top: 1px solid #000;
      //border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.perInfo {

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .FS18 {
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-family: "宋体";
    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: "宋体";
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
    font-family: "宋体";
  }
}

.uline {
  text-decoration: underline;
}
</style>
